<template>
  <!-- <div> -->
  <div :style="{ height: '100%' }">
    <CheeringModal @statChange="setModal" @updateReward="updateReward" :value="modalShow"
      :total_reward="community.total_reward" />
    <iq-card body-class="p-0">
      <div class="user-post-data p-3">
        <div class="d-flex flex-wrap">
          <div class="media-support-info d-c">
            <span class="mb-0" :style="{ fontWeight: 700, color: '#6184FF', fontSize: '16px' }">
              {{ item + ' > ' + community.category }}
            </span>
            <div :style="{ display: 'flex', justifyContent: 'space-between' }">
              <div :style="{ display: 'flex', gap: '1rem', flex: 1 }">
                <div>
                  <i @click="goBeforeAfter('before')" class="ri-arrow-left-s-line"
                    :style="{ fontSize: '4rem', cursor: 'pointer', display: this.before === 0 ? 'none' : 'block' }"></i>
                </div>
                <div :style="{flex: 1}">
                  <div :style="{display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '10px' }">
                    <i v-if="community.image === null" :style="{ color: '#000', fontSize: '30px', marginRight: '8px' }" class="ri-account-circle-line"></i>
                    <img v-else :src="`${imagePath}${community.image}`" :style="{width: '30px', height: '30px', borderRadius: '50%', marginRight: '8px'}"/>
                    <span class="mb-0" :style="{ fontWeight: 500, color: '#000', fontSize: '16px' }">
                      {{ community.name }}
                    </span>
                  </div>
                  <div>
                    <span class="mb-0" :style="{ fontWeight: 700, color: '#000', fontSize: '20px' }">
                      {{ community.title }}
                    </span>
                  </div>
                  <div :style="{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }">
                    <span class="mb-0" :style="{ fontWeight: 400, color: '#828282', fontSize: '14px' }">
                      조회: {{ community.hits.toLocaleString() }} | 추천: {{ community.like_total.toLocaleString() }} | 받은 응원:
                      {{
                        community.get_coin.toLocaleString() }}
                    </span>
                    <span class="mb-0" :style="{ fontWeight: 400, color: '#828282', fontSize: '14px' }">
                      {{ setDate(community.date) }}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <i @click="goBeforeAfter('after')" class="ri-arrow-right-s-line"
                  :style="{ fontSize: '4rem', cursor: 'pointer', display: this.after === 0 ? 'none' : 'block' }"></i>
              </div>
            </div>
            <hr :style="{ borderTop: '1px solid #E0E0E0' }" />
            <div v-html="community.content" :style="{ padding: '25px' }"></div>
          </div>
        </div>
        <div v-if="checkOwner(community.user_id)" class="d-flex align-items-center justify-content-end"
          :style="{ gap: '10px' }">
          <div :style="{ cursor: 'pointer' }" @click="editPosted()">
            수정하기
          </div>
          <div v-show="!this.community.highlight" :style="{ cursor: 'pointer' }" @click="highlightPosted()">
            꽃 달기
          </div>
          <div :style="{ cursor: 'pointer' }" @click="deletePosted()">
            삭제하기
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-3" @click="isLiked(community.like)" :style="{ cursor: 'pointer' }">
              <i :class="'text-primary ' + (community.like ? 'ri-thumb-up-fill' : 'ri-thumb-up-line')"></i>
              <span class="ml-1">추천{{ community.like ? ' 취소' : ' 하기' }}</span>
            </div>
            <div class="d-flex align-items-center mr-3" @click="setModal" :style="{ cursor: 'pointer' }">
              <i class="ri-vip-diamond-line"></i>
              <span class="ml-1">응원하기</span>
            </div>
          </div>
        </div>
      </div>
      <iq-card body-class="p-0" :style="{ boxShadow: 'none' }">
        <div class="comment-area p-3">
          <span :style="{ fontWeight: 700, fontSize: '16px', color: '#000' }">전체 댓글({{
            community.comment_total.toLocaleString() }})</span>
          <!-- 댓글 달기-->
          <b-form class="comment-text d-flex align-items-center mb-3" action="javascript:void(0);">
            <b-form-textarea v-model="commentMessage" class="rounded textarea-auto-height" rows="1" max-rows="3"
              :readonly="!isLogin || !community.comment_allow" :placeholder=placeholder />
            <div class="comment-attagement d-flex">
              <i class="ri-send-plane-line mr-2" :style="{ cursor: 'pointer' }" @click="saveComment(commentMessage)"></i>
            </div>
          </b-form>
          <!-- 댓글 -->
          <div class="post-comments p-0 m-0">
            <div class="mb-2" v-for="(c_item, c_index) in comments" :key="c_item.comment_id">
              <div class="comment-data-block ml-3" :style="{ flex: 1, marginBottom: '30px' }">
                <div :style="{ display: 'flex', justifyContent: 'space-between', flex: 1 }">
                  <div :style="{ width: '100%' }">
                    <div :style="{ display: 'flex' }">
                      <div :style="{ display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                        <i v-if="c_item.image === null" :style="{ color: '#000', fontSize: '30px', marginRight: '8px' }" class="ri-account-circle-line"></i>
                        <img v-else :src="`${imagePath}${c_item.image}`" :style="{width: '30px', height: '30px', borderRadius: '50%', marginRight: '8px'}"/>
                      </div>
                      <h6 :style="{ wordBreak: 'keep-all', display: 'flex', alignItems: 'center' }">
                        {{ c_item.name }}
                        <span class="text-secondary" :style="{ margin: 0, fontSize: '12px' }"
                          v-if="checkPostowner(c_item)">(작성자)
                        </span>
                      </h6>
                    </div>

                    <div :style="{ width: '100%' }">
                      <div v-if="!c_item.edit" class="mb-0"
                        :style="{ paddingLeft: '10px', width: 'calc(100% - 10px)', overflowWrap: 'break-word' }">
                        <span v-if="c_item.type === 0" :style="{ color: '#acacac' }">
                          - 관리자에 의해 블라인드 처리된 댓글입니다. -
                        </span>
                        <span v-else>
                          {{ c_item.comment }}
                        </span>
                      </div>
                      <b-form v-if="c_item.edit" class="comment-text d-flex align-items-center"
                        action="javascript:void(0);">
                        <b-form-textarea v-model="c_item.edit_comment" class="rounded textarea-auto-height" rows="1"
                          max-rows="3" />
                        <div class="comment-attagement d-flex">
                          <b-link href="javascript:void(0);"><i class="ri-send-plane-line mr-2"
                              @click="editComment(c_item)"></i></b-link>
                        </div>
                      </b-form>
                    </div>

                    <div v-if="checkOwner(c_item.user_id)" :style="{ gap: '10px', width: '100%' }"
                      class="d-flex flex-wrap align-items-center comment-activity justify-content-end">
                      <div class="d-flex align-items-center justify-center"
                        :style="{ padding: '3px 5px', borderRadius: '4px', cursor: 'pointer', background: '#032D98', color: '#fff' }"
                        v-if="c_item.edit" @click="openEditComment(c_item)">취소</div>
                      <div
                        :style="{ padding: '3px 5px', borderRadius: '4px', cursor: 'pointer', background: '#032D98', color: '#fff' }"
                        v-else @click="openEditComment(c_item)">수정</div>
                      <div
                        :style="{ padding: '3px 5px', borderRadius: '4px', cursor: 'pointer', background: '#032D98', color: '#fff' }"
                        v-show="!c_item.edit" @click="deleteComment(c_item, c_index)">삭제</div>
                    </div>
                    <div class="d-flex flex-wrap align-items-center comment-activity" :style="{ paddingLeft: '10px' }">
                      <b-link @click="isLikedComment(c_item)">
                        <span class="text-primary"><i
                            :class="'text-primary ' + (c_item.like ? 'ri-thumb-up-fill' : 'ri-thumb-up-line')"></i>
                          <span>
                            추천 ({{
                              c_item.like_total.toLocaleString() }})</span>
                        </span>
                      </b-link>
                      <b-link href="javascript:void(0);" @click="openReply(c_item)">
                        <span class="text-info">
                          대댓글
                          ({{ c_item.reply.length
                          }})
                        </span></b-link>
                      <span>{{ setDate(c_item.date) }}</span>
                    </div>
                  </div>
                </div>
                <!-- 대댓글 리스트 -->
                <div v-if="c_item.add_reply" :style="{ marginTop: '20px' }">
                  <b-form v-if="c_item.add_reply" class="comment-text d-flex align-items-center mt-3 mb-3"
                    action="javascript:void(0);">
                    <b-form-textarea v-model="c_item.add_comment" class="rounded textarea-auto-height" rows="1"
                      max-rows="3" :readonly="!isLogin" />
                    <div class="comment-attagement d-flex">
                      <b-link href="javascript:void(0);"><i class="ri-send-plane-line mr-2"
                          @click="saveReply(c_item)"></i></b-link>
                    </div>
                  </b-form>
                  <div :style="{ paddingLeft: '20px', marginTop: '10px' }" v-for="(r_item, r_index) in c_item.reply"
                    :key="r_item.id">
                    <div class="comment-data-block ml-3" :style="{ width: '100%' }">
                      <div :style="{ display: 'flex', justifyContent: 'space-between' }">
                        <div :style="{ width: '100%' }">
                          <div :style="{ display: 'flex' }">
                            <div :style="{ display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                              <i v-if="r_item.image === null" :style="{ color: '#000', fontSize: '30px', marginRight: '8px' }" class="ri-account-circle-line"></i>
                        <img v-else :src="`${imagePath}${r_item.image}`" :style="{width: '30px', height: '30px', borderRadius: '50%', marginRight: '8px'}"/>
                            </div>
                            <h6 :style="{ wordBreak: 'keep-all', display: 'flex', alignItems: 'center' }">
                              {{ r_item.name }}
                              <span class="text-secondary" :style="{ margin: 0, fontSize: '12px' }"
                                v-if="checkPostowner(r_item)">(작성자)
                              </span>
                            </h6>
                          </div>

                          <div :style="{ width: '100%' }">
                            <div v-if="!r_item.edit" class="mb-0"
                              :style="{ paddingLeft: '10px', width: 'calc(100% - 10px)', overflowWrap: 'break-word' }">
                              <span v-if="r_item.type === 0" :style="{ color: '#acacac' }">
                                - 관리자에 의해 블라인드 처리된 댓글입니다. -
                              </span>
                              <span v-else>
                                {{ r_item.comment }}
                              </span>
                            </div>
                            <b-form v-if="r_item.edit" class="comment-text d-flex align-items-center"
                              action="javascript:void(0);">
                              <b-form-textarea v-model="r_item.edit_comment" class="rounded textarea-auto-height" rows="1"
                                max-rows="3" />
                              <div class="comment-attagement d-flex">
                                <b-link href="javascript:void(0);"><i class="ri-send-plane-line mr-2"
                                    @click="editComment(r_item)"></i></b-link>
                              </div>
                            </b-form>
                          </div>

                          <div v-if="checkOwner(r_item.user_id)" :style="{ gap: '10px', width: '100%' }"
                            class="d-flex flex-wrap align-items-center comment-activity justify-content-end pr-3">
                            <div class="d-flex align-items-center justify-center"
                              :style="{ padding: '3px 5px', borderRadius: '4px', cursor: 'pointer', background: '#032D98', color: '#fff' }"
                              v-if="r_item.edit" @click="openEditComment(r_item)">취소</div>
                            <div
                              :style="{ padding: '3px 5px', borderRadius: '4px', cursor: 'pointer', background: '#032D98', color: '#fff' }"
                              v-else @click="openEditComment(r_item)">수정</div>
                            <div
                              :style="{ padding: '3px 5px', borderRadius: '4px', cursor: 'pointer', background: '#032D98', color: '#fff' }"
                              v-show="!r_item.edit" @click="deleteCommentReply(r_item, c_index, r_index)">삭제</div>
                          </div>
                          <div class="d-flex flex-wrap align-items-center comment-activity"
                            :style="{ paddingLeft: '10px' }">
                            <b-link @click="isLikedComment(r_item)">
                              <span class="text-primary"><i
                                  :class="'text-primary ' + (r_item.like ? 'ri-thumb-up-fill' : 'ri-thumb-up-line')"></i>
                                <span>
                                  추천({{
                                    r_item.like_total.toLocaleString() }})</span>
                              </span>
                            </b-link>
                            <span>{{ setDate(r_item.date) }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </iq-card>
    </iq-card>

    <iq-card>
      <ListForm color="#FFF" :title="category + '의 다른 게시물'" :items="items" :page="current" @isBusy="isBusy"
        :fieldsType="'list'" :total="total" :getMore="getMore" @setItem="setItem" />
    </iq-card>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { core } from '../../config/pluginInit'
import posted from '../../services/dashboard/posted'
import comment from '../../services/dashboard/comment'
import CheeringModal from '../../components/core/modal/cheeringModal.vue'
import { mapActions, mapGetters } from 'vuex'
import Decimal from 'decimal.js'
import ListForm from './Form/ListForm.vue'
import client from '../../services'

export default {
  name: 'DashboardDetail',
  components: {
    CheeringModal,
    ListForm
  },
  mounted () {
    this.getData()
    // this.getList()
    core.index()
    const loggedIn = localStorage.getItem('hypercert_access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.isLogin = true
    }
  },
  watch: {
    $route (to, from) {
      if (to.name === 'dashboard.detail' && from.name === 'dashboard.detail') {
        this.getData()
        // this.getList()
        core.index()
      }
    },
    current (newVal) {
      this.getList()
    }
  },
  data () {
    return {
      imagePath: client.userImage,
      items: [],
      isBusy: true,
      category: this.$route.params.category,
      item: this.$route.params.item,
      total: 0,
      search: '',
      before: 0,
      current: null,
      after: 0,
      modalShow: false,
      community: {
        category: '', // 카테고리
        comment_allow: '', // 댓글 허용
        community_id: '', // pk
        content: '', // 내용
        date: '', // 생성 날짜
        get_coin: '', // 응원
        highlight: '', // 꽃달기
        hits: '', // 조회수
        title: '', // 제목
        user_id: '', // 유저아이디
        name: '', // 유저 닉네임
        like: null, // 추천
        like_total: 0, // 추천 수
        comment_total: 0, // 댓글 수
        total_reward: 0 // 보상
      },
      comments: [],
      placeholder: '',
      isLogin: false,
      commentMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      consumState: 'Category/consumState'
    })
  },
  methods: {
    ...mapActions({
      updateCoin: 'Auth/doCheering'
    }),
    goBeforeAfter (temp) {
      const itemCategory = this.$route.params.item
      const itemSmallCategory = this.$route.params.category
      this.items = []
      this.community = {}
      this.$router.push({
        name: 'dashboard.detail',
        params: {
          item: itemCategory,
          category: itemSmallCategory,
          pk: temp === 'after' ? this.after : this.before
        }
      })
    },
    checkPostowner (item) {
      if (this.community.user_id === item.user_id) {
        return true
      }
      return false
    },
    checkOwner (owner) {
      const token = localStorage.getItem('hypercert_access_token')
      if (token === null || token === undefined) {
        return false
      }
      var base64Payload = token.split('.')[1]
      const payload = Buffer.from(base64Payload, 'base64')
      const result = JSON.parse(payload.toString())
      const iss = result.iss
      if (iss.toString() === owner.toString()) {
        this.postedOwner = true
        return true
      }
      return false
    },
    // 모달 제어
    setModal () {
      const own = this.checkOwner(this.community.user_id)
      if (!this.modalShow) {
        if (own) {
          alert('본인 게시글에 응원하기를 하실수 없습니다.')
          return
        }
      }
      this.modalShow = !this.modalShow
    },
    // 로그인 체크
    checkLogin () {
      if (!this.isLogin) {
        alert('로그인 먼저 해주세요.')
        this.$router.push({ name: 'auth.sign-in' })
        return
      }
      return true
    },
    // 정보 가져오기
    getData () {
      const pk = this.$route.params.pk
      posted.detail(pk).then(res => {
        this.community = res.data.community
        this.comments = res.data.comment
        this.current = res.data.page
        this.before = res.data.before_after.before
        this.after = res.data.before_after.after
        if (!this.community.comment_allow) {
          this.placeholder = '댓글 사용이 금지되어있습니다.'
        }
        if (res.data.community.type !== 1) {
          alert('삭제되거나, 블라인드된 게시글 입니다.')
          this.$router.go(-1)
        }
      })
    },
    // 게시글 수정
    editPosted () {
      this.checkLogin()
      this.$router.push({ name: 'dashboard.edit', params: { item: this.$route.params.item, category: this.$route.params.category, pk: this.community.community_id, data: this.community } })
    },
    // 꽃 달기
    highlightPosted () {
      this.checkLogin()
      const use = new Decimal(this.consumState.highlight)
      const coin = new Decimal(this.userState.coin)
      if (use.comparedTo(coin) === 1) {
        alert(`꽃 달기 할시, ${use}코인이 필요합니다.`)
        return
      }
      posted.highlight(this.community.community_id).then(res => {
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          return
        }
        const ownCoin = new Decimal(this.userState.coin)
        const cheeringCoin = new Decimal(use)
        this.updateCoin(ownCoin - cheeringCoin)
        this.community.highlight = true
        alert('꽃달기를 완료했습니다.')
      })
    },
    // 게시글 삭제
    deletePosted () {
      this.checkLogin()
      posted.delete(this.community.community_id).then(res => {
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          return
        }
        alert('게시글 삭제를 완료했습니다.')
        this.$router.replace({ name: 'dashboard.categoryList', params: { category: this.category } })
      })
    },
    // 게시물 추천
    isLiked (postLike) {
      this.checkLogin()
      posted.like(this.community.community_id)
      this.community.like = !this.community.like
      if (!postLike) {
        this.community.like_total += 1
        this.community.total_reward += 1
      } else {
        this.community.like_total -= 1
        this.community.total_reward -= 1
      }
    },
    // 댓글 달기
    saveComment (item) {
      this.checkLogin()
      if (!this.community.comment_allow) {
        return
      }
      const pk = this.$route.params.pk
      comment.reply(pk, item).then(res => {
        if (res.data.resultCode === 'false' || !res.data.resultCode) {
          if (res.data.message === 'NoPermission') {
            alert('글 작성 권한이 없습니다.')
            return
          }
          alert('서버 오류')
          return
        }
        this.comments.unshift({
          community_id: pk,
          comment_id: res.data.comment_id,
          name: this.userState.name,
          user_id: this.userState.user_id,
          comment: this.commentMessage,
          date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          like: false,
          like_total: 0,
          reply: [],
          add_comment: '',
          add_reply: false,
          image: this.userState.image
        })
        this.commentMessage = ''
        this.community.comment_total += 1
      }
      )
    },

    // 댓글, 대댓글 추천
    isLikedComment (item) {
      this.checkLogin()
      comment.like(item.community_id, item.comment_id)
      item.like = !item.like
      if (item.like) {
        item.like_total += 1
      } else {
        item.like_total -= 1
      }
    },

    // 댓글 삭제
    deleteComment (item, index) {
      this.checkLogin()
      comment.delete(item.comment_id)
        .then(res => {
          if (res.data.resultCode === 'false') {
            return
          }
          this.comments.splice(index, 1)
          this.community.comment_total -= 1
        })
    },

    // 댓글 삭제
    deleteCommentReply (item, cindex, rindex) {
      this.checkLogin()
      comment.delete(item.comment_id)
        .then(res => {
          if (res.data.resultCode === 'false') {
            return
          }
          this.comments[cindex].reply.splice(rindex, 1)
          this.community.comment_total -= 1
        })
    },

    // 대댓글 열기
    openReply (item) {
      item.add_reply = !item.add_reply
    },

    // 댓글 수정창으로 변경
    openEditComment (item) {
      item.edit = !item.edit
    },
    // 댓글 수정창으로 변경
    editComment (item) {
      comment.patch(item.comment_id, item.edit_comment)
        .then(res => {
          item.edit = false
          item.comment = item.edit_comment
        })
    },
    // 대댓글 저장
    saveReply (item) {
      this.checkLogin()
      comment.commentReply(item.comment_id, item.add_comment)
        .then(res => {
          if (res.data.resultCode === 'false' || !res.data.resultCode) {
            if (res.data.message === 'NoPermission') {
              alert('글 작성 권한이 없습니다.')
              return
            }
            alert('서버 오류')
            return
          }
          item.reply.unshift({
            community_id: item.community_id,
            comment_id: res.data.comment_id,
            name: this.userState.name,
            user_id: this.userState.user_id,
            comment: item.add_comment,
            date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            like: false,
            like_total: 0,
            image: this.userState.image
          })
          item.add_comment = ''
          this.community.comment_total += 1
        })
    },
    updateReward (data) {
      const total = new Decimal(this.community.get_coin)
      const coin = new Decimal(data)

      // this.community.total_reward = total + coin
      this.community.get_coin = Decimal.add(total, coin)
    },
    setDate (data) {
      // var date = new Date()
      // var utcNow = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      //   date.getUTCDate(), date.getUTCHours(),
      //   date.getUTCMinutes(), date.getUTCSeconds())
      // const today = new Date(utcNow)
      // const item = new Date(data)
      // var utcTarget = Date.UTC(item.getUTCFullYear(), item.getUTCMonth(),
      //   item.getUTCDate(), item.getUTCHours(),
      //   item.getUTCMinutes(), item.getUTCSeconds())
      // const timeValue = new Date(utcTarget)
      // const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60)
      // if (betweenTime < 1) return '방금전'
      // if (betweenTime < 60) {
      //   return `${betweenTime}분전`
      // }
      // const betweenTimeHour = Math.floor(betweenTime / 60)
      // if (betweenTimeHour < 24) {
      //   return `${betweenTimeHour}시간전`
      // }

      // const betweenTimeDay = Math.floor(betweenTime / 60 / 24)
      // if (betweenTimeDay < 365) {
      //   return `${betweenTimeDay}일전`
      // }

      // return `${Math.floor(betweenTimeDay / 365)}년전`
      const milliSeconds = new Date() - new Date(data)
      const seconds = milliSeconds / 1000
      if (seconds < 60) return '방금 전'
      const minutes = seconds / 60
      if (minutes < 60) return `${Math.floor(minutes)}분 전`
      const hours = minutes / 60
      if (hours < 24) return `${Math.floor(hours)}시간 전`
      const days = hours / 24
      if (days < 7) return `${Math.floor(days)}일 전`
      const weeks = days / 7
      if (weeks < 5) return `${Math.floor(weeks)}주 전`
      const months = days / 30
      if (months < 12) return `${Math.floor(months)}개월 전`
      const years = days / 365
      return `${Math.floor(years)}년 전`
    },
    getList () {
      // TODO
      if (this.current === null) return
      posted.getList(this.category, 10, this.current - 1, this.search).then(res => {
        if (res.status !== 200) {
          return alert('서버 오류')
        }
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          this.setBusy()
          return
        }
        this.setBusy()
        if (res.data.community.length > 0) {
          this.fieldsType = 'list'
          this.items = res.data.community
          this.total = res.data.total
        } else {
          this.fieldsType = 'span'
          this.items = [{ pk: '', title: '작성된 글이 없습니다.' }]
        }
      })
    },
    getMore (page, temp) {
      return posted.getList(this.category, 10, page, temp)
    },
    setItem (item, total) {
      this.items = item
      this.total = total
    },
    setBusy () {
      this.isBusy = !this.isBusy
    },
    getSearch (e) {
      e.preventDefault()
      this.setBusy()
      posted.getList(this.category, 10, 0, this.search).then(res => {
        if (res.data.resultCode === 'false') {
          return
        }
        this.items = res.data.community
        this.total = res.data.total
        this.setBusy()
      })
    }
  }
}
</script>
<style>
.a {
  text-decoration: none;
}</style>
