<template>
  <div>
    <b-modal id="CheeringModal"
             v-model="modalShow"
             @hide="statChange"
             footer-class="justify-content-center"
             size="md"
             no-close-on-backdrop
             no-close-on-esc
             centered
             scrollable>

      <template #modal-header="{ close }">
        <h5 class="modal-title">응원하기</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

        <p>응원하실 코인을 입력해주세요.<br />사용가능수량: {{ this.userState.coin.toString() }} TIPS</p>
      <b-form class="comment-text d-flex align-items-center" action="javascript:void(0);">
        <b-form-input type="number" v-model="coin" class="rounded" />
        <div class="comment-attagement d-flex">
        </div>
        </b-form>

      <template #modal-footer="{ cancel, ok }">
        <b-button size="sm" variant="danger" @click="cancelCherring(cancel)">취소</b-button>
        <b-button size="sm" variant="success" @click="doCheering(ok)">확인</b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import posted from '../../../services/dashboard/posted'
import { mapActions, mapGetters } from 'vuex'
import Decimal from 'decimal.js'

export default {
  name: 'CheeringModal',
  props: [
    'value',
    'total_reward'
  ],
  data () {
    return {
      modalShow: this.value,
      coin: '',
      reward: this.total_reward
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState'
    })
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  },
  methods: {
    ...mapActions({
      updateCoin: 'Auth/doCheering'
    }),
    statChange () {
      this.$emit('statChange')
    },
    cancelCherring (cancel) {
      this.coin = ''
      cancel()
    },
    doCheering (ok) {
      const cheeringCoin = new Decimal(this.coin)
      if (cheeringCoin < new Decimal(0)) {
        alert('0보다 작은값은 불가능합니다.')
        return
      }
      const ownCoin = new Decimal(this.userState.coin)
      const pk = this.$route.params.pk
      // if (decimalMatic.comparedTo(walletCoin) === 1) {
      // if (ownCoin < cheeringCoin) {
      if (cheeringCoin.comparedTo(ownCoin) === 1) {
        alert('사용가능수량 보다 많은 응원은 하실수 없습니다.')
        return
      }
      posted.cheering(pk, this.coin * 1).then(res => {
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          return
        }
        this.updateCoin(ownCoin - cheeringCoin)
        this.$emit('updateReward', this.coin)
        this.coin = ''
        alert('응원하기가 완료되었습니다.')
        ok()
      })
    }
  }
}
</script>
