import client from '../index'

export default {
  /**
   * 댓글 생성
   *
   * @param {number} communityId pk
   * @param {string} comment 내용
   */
  async reply (communityId, comment) {
    const postData = {
      comment: comment
    }
    try {
      return await client.json.post(`/comments/${communityId}`, postData)
    } catch (err) {
      // console.log('<POST> comment.reply')
      // console.log(err)
    }
  },

  /**
   * 대댓글 생성
   *
   * @param {number} commentId pk
   * @param {string} comment 내용
   */
  async commentReply (commentId, comment) {
    const postData = {
      comment: comment
    }
    try {
      return await client.json.post(`/recomments/${commentId}`, postData)
    } catch (err) {
      // console.log('<POST> comment.commentReply')
      // console.log(err)
    }
  },

  /**
   * 댓글, 대댓글 수정
   *
   * @param {number} commentId pk
   * @param {string} comment 내용
   */
  async patch (commentId, comment) {
    const postData = {
      comment: comment
    }
    try {
      return await client.json.patch(`/comments/${commentId}`, postData)
    } catch (err) {
      // console.log('<POST> comment.patch')
      // console.log(err)
    }
  },

  /**
   * 댓글, 대댓글 좋아요
   *
   * @param {number} communityId 게시글 pk
   * @param {number} commentId 댓글 pk
   */
  async like (communityId, commentId) {
    try {
      const postData = {
        community_id: communityId
      }
      return await client.json.put(`/comments/${commentId}`, postData)
    } catch (err) {
      // console.log('<PUT> comment.like')
      // console.log(err)
    }
  },

  /**
   * 댓글, 대댓글 삭제
   *
   * @param {number} commentId pk
   */
  async delete (commentId) {
    try {
      return await client.json.delete(`/comments/${commentId}`)
    } catch (err) {
      // console.log('<POST> comment.delete')
      // console.log(err)
    }
  }
}
